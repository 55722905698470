<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- 登入表單 -->
      <b-card class="mb-0">

        <!-- 標題 -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary">
            登入
          </h2>
        </b-link>

        <!-- 表單 -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- 信箱 -->
            <b-form-group
              label-for="email"
              label="帳號"
            >
              <validation-provider
                #default="{ errors }"
                name="信箱"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="123@gmail.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- 密碼 -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">密碼</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>忘記密碼?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="請輸入使用者密碼"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                記住我
              </b-form-checkbox>
            </b-form-group>

            <!-- 登入按鈕 -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="onSubmitLogin"
            >
              登入
            </b-button>
          </b-form>
        </validation-observer>

        <!-- 註冊 -->
        <b-card-text class="text-center mt-2">
          <span>還不是我們的會員嗎? </span>
          <b-link :to="{name:'auth-register'}">
            <span>註冊帳號</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            或
          </div>
        </div>

        <!-- 社群媒體 -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /登入表單 -->
    </div>

    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      sitekey="6LeKGO0aAAAAAFoXqpakoMDb8JgP8lmjPa7xwx09"
      @verify="login"
    />
  </div>
</template>

<script>
// API
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { required, email } from '@validations'

// UI
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@/@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

// Components
import { useToast } from 'vue-toastification/composition'
import VueRecaptcha from 'vue-recaptcha'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,

    VuexyLogo,

    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',

      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    // 提醒彈窗
    Alert(state, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `登入${state ? '成功' : '失敗'}`,
          icon: state ? 'CheckIcon' : 'InfoIcon',
          variant: state ? 'success' : 'danger',
          text: message,
        },
      },
      {
        position: 'bottom-right',
      })
    },

    // 觸發登入
    onSubmitLogin() {
      this.$refs.recaptcha.execute()
    },

    // 登入
    login(recaptchaToken) {
      this.$refs.loginForm.validate().then(isFinish => {
        if (isFinish) {
          useJwt.login({
            acc: this.userEmail,
            pw: this.password,
            rc_token: recaptchaToken,
          })
            .then(response => {
              if (response.data.success) {
                useJwt.setToken(response.data.data.token)
                const { data } = response.data
                localStorage.setItem('userData', JSON.stringify(data))
                this.$store.commit('app/UPDATE_USERDATA_STATE', data)
                this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_DISPLAY', data.role.isAdmin === 0 ? 'Client' : 'Admin')
                this.$router.replace(getHomeRouteForLoggedInUser(data.role.isAdmin === 0 ? 'Client' : 'Admin'))
                  .then(() => {
                    this.Alert(response.data.success, response.data.message, 'bottom-right')
                  })
              } else {
                this.Alert(response.data.success, response.data.message, 'bottom-right')
              }
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
      this.$refs.recaptcha.reset()
    },

  },
  setup() {
    localize('tw')
    const Alert = router.currentRoute.params.alert

    const toast = useToast()

    if (Alert) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: Alert,
          icon: 'InfoIcon',
          variant: 'warning',
        },
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper{
  min-height: 75vh;
}
</style>
